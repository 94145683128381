/* Slideshow */
.tab {

  display: flex !important;
  align-items: center !important;
}

.tab img {
  height: 15vh;
  margin-left: auto !important;
  margin-right: auto !important;
}

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 500px;
  position: relative;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;

  height: 400px;
  width: 100%;
  border-radius: 40px;
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}


.slide22 {
  margin-left: 20%;
  top: 20%;
  margin-top: 20%;
}